import React from "react";
import styled from "styled-components";
import { Option, QuizWithResultsResponse, Question } from "../interfaces";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  .vs {
    align-self: center;
    font-weight: 700;
  }
  @media (max-width: 700px) {
    grid-auto-flow: row;
  }
`;

const OptionResult = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.075);
  grid-gap: 40px;
`;

export const getVoteCount = (
  questionUuid: Question["uuid"],
  optionUuid: Option["uuid"],
  submissions: QuizWithResultsResponse["submissions"]
) => {
  return Object.entries(submissions).reduce((total, current) => {
    const responseDataResults = current[1].results;
    return responseDataResults[questionUuid] === optionUuid ? total + 1 : total;
  }, 0);
};

const toPercentage = (amt: number, total: number): string => {
  const percentage = (amt / total) * 100;
  return `${percentage.toFixed(2)}%`;
};

interface Props {
  option1: Option;
  option2: Option;
  quizResponseData: QuizWithResultsResponse;
  questionUuid: Question["uuid"];
}

export const ResultsTable = ({
  option1,
  option2,
  quizResponseData,
  questionUuid,
}: Props) => {
  const option1VoteCount = getVoteCount(
    questionUuid,
    option1.uuid,
    quizResponseData.submissions
  );
  const option2VoteCount = getVoteCount(
    questionUuid,
    option2.uuid,
    quizResponseData.submissions
  );
  return (
    <Container>
      <OptionResult>
        <span>
          <p>Option 1</p>
          <h3>{option1.data.text}</h3>
        </span>
        <span>
          <p>vote count</p>
          <h3>{option1VoteCount}</h3>
        </span>
        <span>
          <p>% of votes</p>
          <h3>
            {toPercentage(
              option1VoteCount,
              Object.keys(quizResponseData.submissions).length
            )}
          </h3>
        </span>
      </OptionResult>
      <span className="vs">vs.</span>
      <OptionResult>
        <span>
          <p>Option 2</p>
          <h3>{option2.data.text}</h3>
        </span>
        <span>
          <p>vote count</p>
          <h3>{option2VoteCount}</h3>
        </span>
        <span>
          <p>% of votes</p>
          <h3>
            {toPercentage(
              option2VoteCount,
              Object.keys(quizResponseData.submissions).length
            )}
          </h3>
        </span>
      </OptionResult>
    </Container>
  );
};

export default ResultsTable;
