import React from "react";
import styled from "styled-components";

import QuizResults from "../components/quiz-results/QuizResults";

import { Layout } from "../layouts/Layout";
import SEO from "../components/Seo";

const ResultsContainer = styled.section`
  padding: 25px;
`;

export const QuizResultsPage = ({ location }: any) => {
  return (
    <Layout>
      <SEO title={"Poll Results"} />
      <ResultsContainer>
        <QuizResults location={location} />
      </ResultsContainer>
    </Layout>
  );
};

export default QuizResultsPage;
