import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { AuthContext } from "../AuthContext";

import { QuizWithResultsResponse } from "../interfaces";
import Loading from "../Loading";
import ResultsTable from "./ResultsTable";
import { Link } from "gatsby";

interface Props {
  location: Location;
}

const QuizContainer = styled.div`
  display: grid;
  text-align: center;
  padding: 0px 80px;
  @media (max-width: 700px) {
    padding: 10px;
  }
  h1 {
    margin-top: 0;
    font-family: "Bangers";
    font-size: 3rem;
    padding: 0.5rem 2rem;
    display: grid;
    place-items: center;
    text-shadow: -2px -2px yellow, 2px 2px orange, 4px 4px orange;
    background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  }
`;

const Question = styled.div<{ active: boolean }>`
  display: grid;
  grid-gap: 10px;
  padding: 25px 50px;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.1);
  justify-self: center;
  width: 90%;
  max-width: 2200px;
  h2 {
    margin: 0;
  }
  h3 {
    justify-self: start;
    margin-top: 0;
    span {
      color: #666;
    }
  }
  button {
    cursor: pointer;
    justify-self: center;
    text-align: center;
    max-width: 400px;
    padding: 25px;
    text-decoration: none;
    color: #222;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
    &:hover {
      opacity: 0.75;
    }
    border: 2px solid greenyellow;
  }
`;

const QuizHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 0.8rem;
  }
`;

const AllResults = styled.div`
  display: grid;
  grid-gap: 30px;
  h2 {
    justify-self: start;
    margin-top: 0;
  }
`;

const QuizResults = ({ location }: Props) => {
  const { auth, getQuizWithResultsByUuid } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [quizResponseData, setQuizResponseData] = useState<
    QuizWithResultsResponse
  >(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("id");
    const fetchQuizWithResults = async () => {
      const resData = await getQuizWithResultsByUuid(query);
      if (resData) {
        setQuizResponseData(resData);
      }
      setLoading(false);
    };
    if (auth.isUserSignedIn) {
      fetchQuizWithResults();
    }
  }, [auth]);

  return (
    <QuizContainer>
      {loading && <Loading />}
      {!loading && !quizResponseData?.submissions && (
        <>
          <p>It looks like this poll doesn't have any responses yet.</p>
          <Link to="/my-polls">Back to My Polls</Link>
        </>
      )}
      {!loading && quizResponseData?.submissions && (
        <>
          <QuizHeader>
            <h1>{quizResponseData.quizData.name}</h1>
          </QuizHeader>
          {Object.entries(quizResponseData.quizData.questions).length === 0 ? (
            <p>No results yet!</p>
          ) : (
            <>
              <h2>Vote Results</h2>
              <AllResults>
                {Object.entries(quizResponseData.quizData.questions).map(
                  (record) => {
                    const { title, options, uuid } = record[1];
                    const option1 = options[Object.keys(options)[0]];
                    const option2 = options[Object.keys(options)[1]];
                    return (
                      <Question key={uuid} active={false}>
                        <h3>
                          <span>Q. </span>
                          {title}
                        </h3>
                        <ResultsTable
                          option1={option1}
                          option2={option2}
                          questionUuid={uuid}
                          quizResponseData={quizResponseData}
                        />
                      </Question>
                    );
                  }
                )}
              </AllResults>
            </>
          )}
        </>
      )}
    </QuizContainer>
  );
};

export default QuizResults;
